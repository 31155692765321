<div class="filters tab-bar">
  <div class="inner">
    <reports-filters
      *ngIf="!useSidebarFilter && !selectMode"
      (search)="search()"
      [disabled]="selectMode && selectedReports?.length">
    </reports-filters>
    <mm-navigation></mm-navigation>
    <mm-search-bar
      *ngIf="useSidebarFilter && !selectMode"
      (toggleFilter)="toggleFilter()"
      (search)="search()"
      [disabled]="selectMode && selectedReports?.length"
      [showFilter]="true">
    </mm-search-bar>
    <mm-multiselect-bar *ngIf="selectModeAvailable && selectMode && selectedReports?.length"
      [display]="'mobile'"
      [selectedCount]="selectedReports?.length"
      [areAllReportsSelected]="areAllReportsSelected()"
      (deleteItems)="bulkDeleteReports()"
      (selectItems)="selectAllReports()"
      (deselectItems)="deselectAllReports()">
    </mm-multiselect-bar>
    <mm-reports-more-menu (exportReports)="exportReports()"></mm-reports-more-menu>
  </div>
</div>

<div class="inner">
  <div class="inbox page">

    <div id="reports-list"
      class="inbox-items left-pane"
      [ngClass]="{ 'col-sm-3': isSidebarFilterOpen, 'col-sm-4': !isSidebarFilterOpen, 'select-mode-available': selectModeAvailable }">

      <div class="select-all" *ngIf="!loading && selectModeAvailable && reportsList?.length">
        <input type="checkbox"
          (click)="areAllReportsSelected() ? deselectAllReports() : selectAllReports()"
          [checked]="areAllReportsSelected()"
          [indeterminate]="areSomeReportsSelected()">
        <span class="select-all-label" (click)="areAllReportsSelected() ? deselectAllReports() : selectAllReports()">
          {{ (areAllReportsSelected() ? 'select.mode.deselect.all' : 'select.mode.select.all') | translate }}
        </span>
      </div>

      <div class="items-container">
        <ul [hidden]="loading && !appending">
          <li *ngFor="let report of reportsList; trackBy: listTrackBy"
            [attr.data-record-id]="report._id"
            [class.selected-to-view] = "selectedReport?.doc?._id === report._id"
            [class.unread]="!report.read"
            (click)="selectReportRow(report)"
            class="content-row">
            <input type="checkbox" (click)="selectReport(report)" [checked]="selectMode && report.selected">
            <a [routerLink]="['/reports', report._id]">
              <div *ngIf="report.icon" class="icon" [innerHTML]="report.icon | resourceIcon"></div>
              <div class="content">
                <div class="heading">
                  <h4><span>{{ report.heading }}</span></h4>
                  <div class="date"><span [innerHTML]="report.reported_date | relativeDate"></span></div>
                </div>

                <div *ngIf="report.summary" class="summary">
                  <p>{{ report.summary }}</p>
                  <div *ngIf="(!report.valid || report.verified !== undefined)" class="status">
                    <span [class.verified]="report.valid && report.verified" [class.error]="!(report.valid && report.verified)" class="verification-badge">
                      <report-verify-valid-icon *ngIf="report.valid && report.verified"></report-verify-valid-icon>
                      <report-verify-invalid-icon *ngIf="!(report.valid && report.verified)"></report-verify-invalid-icon>
                    </span>
                  </div>
                </div>

                <div *ngIf="report.lineage?.length" class="detail" [innerHTML]="report.lineage | lineage"></div>
              </div>
            </a>
          </li>
        </ul>

        <p *ngIf="error" class="alert alert-danger" role="alert">
          <span *ngIf="errorSyntax"><span>{{ 'invalid.query' | translate }}</span></span>
          <span *ngIf="!errorSyntax">{{ 'Error fetching reports' | translate }}</span>
        </p>
        <p class="loading-status" *ngIf="!error && !loading && !hasReports">{{ 'reports.none' | translate }}</p>
        <p class="loading-status" *ngIf="!loading && hasReports && !moreItems">{{ 'No more reports' | translate }}</p>
        <div class="loader" *ngIf="loading"></div>
        <div class="padding"></div>
      </div>

      <mm-fast-action-button
        [config]="{ button: { type: getFastActionButtonType(), label: 'report.new_report.button' } }"
        [fastActions]="fastActionList">
      </mm-fast-action-button>
    </div>

    <div class="content-pane right-pane">
      <div class="item-content" [ngClass]="{ 'col-sm-6': isSidebarFilterOpen, 'col-sm-8': !isSidebarFilterOpen }">
        <mm-multiselect-bar *ngIf="selectModeAvailable && selectMode && selectedReports?.length"
          [selectedCount]="selectedReports?.length"
          (deleteItems)="bulkDeleteReports()">
        </mm-multiselect-bar>
        <router-outlet></router-outlet>
      </div>

      <mm-reports-sidebar-filter
        class="col-sm-3 sidebar-filter-wrapper"
        *ngIf="useSidebarFilter"
        (search)="search()"
        [disabled]="selectMode && selectedReports?.length">
      </mm-reports-sidebar-filter>
    </div>
  </div>
</div>
