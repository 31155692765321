<mm-modal-layout
  id="edit-user-settings"
  [processing]="processing"
  [error]="error"
  [titleKey]="'Edit User Profile'"
  [submitKey]="'save'"
  [submittingKey]="'saving'"
  [isFlatButton]="true"
  (onCancel)="close()"
  (onSubmit)="editUserSettings()"
>

  <form action="" method="POST">

    <div class="form-group">
      <label for="name">{{'User Name' | translate}}</label> *
      <input id="name" type="text" class="form-control" name="username" [(ngModel)]="editUserModel.username" [disabled]="editUserModel.id" />
    </div>

    <div class="form-group">
      <label for="fullname">{{'Full Name' | translate}}</label>
      <input id="fullname" type="text" class="form-control" name="fullname"
             [(ngModel)]="editUserModel.fullname" />
    </div>

    <div class="form-group">
      <label for="email">{{'Email Address' | translate}}</label>
      <input id="email" type="text" class="form-control" name="email"
             [(ngModel)]="editUserModel.email" />
    </div>

    <div class="form-group">
      <label for="phone">{{'Phone Number' | translate}}</label>
      <input id="phone" type="text" class="form-control" name="phone"
             [(ngModel)]="editUserModel.phone"/>
    </div>

    <div class="form-group">
      <label for="language">{{'Language' | translate}}</label>
      <select id="language" class="form-control" [(ngModel)]="editUserModel.language.code" name="language">
        <option [value]="locale.code" *ngFor="let locale of enabledLocales; trackBy: listTrackBy">
          {{locale.name}}
        </option>
      </select>
    </div>

  </form>

</mm-modal-layout>
