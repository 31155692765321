<span *ngIf="!value?.placeholder; else valuePlaceholderTpl">
  <span *ngIf="target?.isPercent; else targetIsNotPercentTpl">
    {{ value?.percent | localizeNumber }}%
    <span translate [translateParams]="value | localizeNumber">{{ target?.percentage_count_translation_key || 'targets.count.default' }}</span>
  </span>
  <ng-template #targetIsNotPercentTpl>
    <span>{{ value?.pass | localizeNumber }}</span>
  </ng-template>
</span>

<ng-template #valuePlaceholderTpl>
  <span>{{'analytics.target.aggregates.no.data' | translate}}</span>
</ng-template>
