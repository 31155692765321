<div class="content-pane right-pane">

  <div class="col-sm-8 item-content empty-selection" *ngIf="loadingContent">
    <div>
      <div class="loader"></div>
    </div>
  </div>

  <div class="col-sm-8 item-content empty-selection" *ngIf="!selectedContact && !loadingContent && !summaryErrorStack">
    <div>{{'No contact selected' | translate}}</div>
  </div>

  <div class="col-sm-8 item-content empty-selection" *ngIf="selectedContact?.error && !loadingContent">
    <div>{{'contact.select.error' | translate}}</div>
  </div>

  <div class="item-content" *ngIf="!!summaryErrorStack && !loadingContent">
    <error-log [errorStack]="summaryErrorStack" [errorFor]="'contacts'"></error-log>
  </div>

  <div class="col-sm-8 item-content" *ngIf="!selectedContact?.error && !loadingContent && !summaryErrorStack">
    <mm-fast-action-button [fastActions]="fastActionList"></mm-fast-action-button>
    <div class="material">
      <div class="body meta">
        <div class="card">
          <div class="row heading" [ngClass]="{'deceased': selectedContact?.doc?.date_of_death, 'muted':selectedContact?.doc?.muted }">
            <span [innerHTML]="selectedContact?.type?.icon | resourceIcon"></span>
            <div class="heading-content">
              <h2 [attr.test-id]="'contact-name'">{{selectedContact?.doc?.name}}</h2>
              <div [attr.test-id]="'deceased-title'" *ngIf="selectedContact?.doc?.date_of_death">{{'contact.deceased.title' | translate}}</div>
              <div class="primary-contact" *ngIf="selectedContact?.isPrimaryContact">
                <i class="fa fa-star primary"></i>
                <span>{{selectedContact?.type?.primary_contact_key || 'Primary contact' | translate}}</span>
              </div>
              <div class="muted" *ngIf="selectedContact?.doc?.muted">{{'contact.muted' | translate}}</div>
            </div>
          </div>

          <div class="loader" *ngIf="contactsLoadingSummary"></div>
          <div class="row flex grid" id="contact_summary" *ngIf="!contactsLoadingSummary">
            <div class="col col-xs-12 col-sm-{{field.width || 12}}" *ngFor="let field of selectedContact?.summary?.fields">
              <div class="cell {{field.label | lowercase}}">
                <span *ngIf="field.icon" class="field-icon" [innerHTML]="field.icon | resourceIcon"></span>
                <div>
                  <label translate *ngIf="field.label">{{field.label}}</label>
                  <p *ngIf="field.translate" translate [translateParams]="field.context">{{field.value}}</p>
                  <p *ngIf="!field.translate && field.filter" [innerHTML]="field.value"></p>
                  <p *ngIf="!field.translate && !field.filter">{{field.value}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="selectedContact?.summary">
          <div *ngFor="let card of selectedContact?.summary?.cards">
            <div class="card compact-card" [attr.test-id]="card.label">
              <div class="action-header cell">
                <h3>{{card.label | translate}}</h3>
              </div>
              <div class="row flex grid">
                <div class="col col-xs-12 col-sm-{{field.width || 12}}" *ngFor="let field of card.fields">
                  <div class="cell">
                    <span *ngIf="field.icon" class="field-icon" [innerHTML]="field.icon | resourceIcon"></span>
                    <div [attr.test-id]="field.label">
                      <label *ngIf="field.label">{{field.label | translate}}</label>
                      <p class="card-field-value" *ngIf="field.translate" translate [translateParams]="field.context">{{field.value}}</p>
                      <p class="card-field-value" *ngIf="!field.translate && field.filter" [innerHTML]="field.value"></p>
                      <p class="card-field-value" *ngIf="!field.translate && !field.filter">{{field.value}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="card compact-card children" [ngClass]="group?.type?.person ? 'persons' : 'places'" *ngFor="let group of selectedContact?.children">
        <div class="action-header cell">
          <div class="col-xs-10">
            <h3>{{group?.type?.group_key | translate}}</h3>
          </div>
          <div *ngIf="group?.type?.person" mmAuth="can_view_tasks" class="col-xs-2 tasks-title">{{'Tasks' | translate}}</div>
        </div>

        <ul>
          <ng-container *ngFor="let child of group?.contacts" >
            <mm-content-row *ngIf="!child.deceased"
              [id]="child.doc._id"
              [route]="['/', 'contacts', child.doc._id]"
              [heading]="child.doc.name"
              [summary]="''"
              [primaryContact]="child.isPrimaryContact"
              [dob]="child.doc.date_of_birth"
              [taskCount]="child.taskCount"
              [muted]="child.doc.muted"
              [displayMuted]="true"
              [valid]="true"
            ></mm-content-row>
          </ng-container>
        </ul>
        <ul class="action deceased">
          <li *ngIf="group?.deceasedCount > 0" class="content-row deceased">
            <a [routerLink]="['/contacts', selectedContact?.doc?._id, 'deceased']">
              <span translate [translateParams]="{ count: group.deceasedCount | localizeNumber }">contact.deceased.view</span>
              <i class="fa fa-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>

      <div *ngIf="selectedContact?.tasks" mmAuth="can_view_tasks">
        <div class="card compact-card tasks">
          <div class="action-header cell">
            <h3>{{'Tasks' | translate}}</h3>
            <span class="table-filter">
              <a (click)="filterTasks(1)" class="btn btn-link" [class.selected]="tasksTimeWindowWeeks === 1" translate [translateParams]="{ WEEKS: 1 }">n.week</a>
              <a (click)="filterTasks(2)" class="btn btn-link" [class.selected]="tasksTimeWindowWeeks === 2" translate [translateParams]="{ WEEKS: 2 }">n.week</a>
              <a (click)="filterTasks()" class="btn btn-link" [class.selected]="!tasksTimeWindowWeeks">{{'view.all' | translate}}</a>
            </span>
          </div>
          <ul>
            <mm-content-row *ngFor="let task of filteredTasks"
              [id]="task._id"
              [route]="['/', 'tasks', task._id]"
              [icon]="task.icon"
              [date]="task.dueDate"
              [overdue]="task.overdue"
              [dateFormat]="'taskDueDate'"
              [heading]="selectedContact?.children?.length ? task.contact?.name : task.title"
              [summary]="selectedContact?.children?.length ? task.title : ''"
              [warning]="task.priorityLabel"
            ></mm-content-row>
          </ul>
          <div class="row" *ngIf="!filteredTasks.length">
            <div class="cell col">
              <span *ngIf="!tasksTimeWindowWeeks">{{'tasks.none' | translate}}</span>
              <span *ngIf="tasksTimeWindowWeeks" translate [translateParams]="{ WEEKS: tasksTimeWindowWeeks }">tasks.none.n.weeks</span>
            </div>
          </div>
        </div>
      </div>

      <div mmAuth="can_view_reports">
        <div class="card compact-card reports">
          <div class="action-header cell">
            <h3>{{'Reports' | translate}}</h3>
            <span class="table-filter">
              <a (click)="filterReports(3)" class="btn btn-link" [class.selected]="reportsTimeWindowMonths === 3" translate [translateParams]="{ MONTHS: 3 }">n.month</a>
              <a (click)="filterReports(6)" class="btn btn-link" [class.selected]="reportsTimeWindowMonths === 6" translate [translateParams]="{ MONTHS: 6 }">n.month</a>
              <a (click)="filterReports()" class="btn btn-link" [class.selected]="!reportsTimeWindowMonths">{{'view.all' | translate}}</a>
            </span>
          </div>
          <div class="loader" *ngIf="loadingSelectedContactReports"></div>
          <div *ngIf="!loadingSelectedContactReports">
            <ul>
              <mm-content-row *ngFor="let report of filteredReports"
                [id]="report._id"
                [route]="['/', 'reports', report._id]"
                [icon]="report | formIconName:forms"
                [date]="report.reported_date"
                [heading]="selectedContact?.children?.length ? report.heading : (report | summary:forms)"
                [summary]="selectedContact?.children?.length ? (report | summary:forms) : ''"
                [showStatus]="true"
                [valid]="report.valid"
                [verified]="report.verified"
              ></mm-content-row>
            </ul>
            <div class="row" *ngIf="!filteredReports.length">
              <div class="cell col">
                <span *ngIf="!reportsTimeWindowMonths">{{'reports.none' | translate}}</span>
                <span *ngIf="reportsTimeWindowMonths" translate [translateParams]="{ MONTHS: reportsTimeWindowMonths }">reports.none.n.months</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="padding"></div>
    </div>
  </div>
</div>
