<mm-modal-layout
  danger="true"
  [titleKey]="'Log Out'"
  [submitKey]="'Log Out'"
  [cancelKey]="'Cancel'"
  (onCancel)="close()"
  (onSubmit)="submit()"
>
  <p>{{'confirm.logout' | translate}}</p>
</mm-modal-layout>
