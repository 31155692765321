<mm-modal-layout
  id="check-date"
  [titleKey]="'date.incorrect.title'"
  [submitKey]="'date.incorrect.confirm'"
  [hideCancelButton]="true"
  (onCancel)="close()"
  (onSubmit)="close()"
>
  <p>{{'date.incorrect.description' | translate}}</p>
  <p>{{'date.incorrect.advice' | translate}}</p>
  <p>
    <span>{{'date.incorrect.reported' | translate}}</span>:
    <span>{{reportedLocalDate | simpleDateTime}}</span>
  </p>
  <p *ngIf="expectedLocalDate">
    <span>{{'date.incorrect.expected' | translate}}</span>:
    <span>{{expectedLocalDate | simpleDateTime}}</span>
  </p>
</mm-modal-layout>
