<mm-modal-layout
  id="session-expired"
  [titleKey]="'SESSION_EXPIRED_TITLE' | bootstrapTranslate"
  [submitKey]="'LOGIN' | bootstrapTranslate"
  [hideCancelButton]="true"
  (onCancel)="close()"
  (onSubmit)="submit()"
>
  <p>{{'SESSION_EXPIRED_DESCRIPTION' | bootstrapTranslate}}</p>
</mm-modal-layout>
