<section class="multiselect-bar-container {{ display }}">
  <div>
    <a class="deselect-all fa fa-chevron-left" (click)="deselectItems.emit()"></a>

    <div class="selection-count">
      <span class="minimal" *ngIf="selectedCount">{{ selectedCount }}</span>
      <span class="count-label" *ngIf="selectedCount === 1">{{ 'select.mode.count.singular' | translate }}</span>
      <span class="count-label" *ngIf="selectedCount !== 1" translate [translateParams]="{ number: selectedCount | localizeNumber }">select.mode.count.plural</span>
    </div>

    <a class="select-all-label" *ngIf="!areAllReportsSelected" (click)="selectItems.emit()">{{ 'select.mode.select.all' | translate }}</a>
  </div>
  <a class="bulk-delete" (click)="deleteItems.emit()">
    <span class="bulk-delete-icon fa fa-trash"></span>
    <span class="bulk-delete-label">{{ 'select.mode.delete.all' | translate }}</span>
  </a>
</section>
