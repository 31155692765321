<mm-modal-layout
  id="bulk-delete-confirm"
  [processing]="processing"
  [error]="error"
  [titleKey]="docs.length > 1 ? 'bulkdelete.confirm.title.plural' : 'bulkdelete.confirm.title'"
  [submitKey]="'bulkdelete.confirm.action'"
  [submittingKey]="'Deleting'"
  [cancelKey]="'Cancel'"
  (onCancel)="close()"
  (onSubmit)="submit()"
>
  <p *ngIf="!processing && docs.length === 1">{{ 'confirm.delete' | translate }}</p>
  <p *ngIf="!processing && docs.length !== 1" translate [translateParams]="{ number: docs.length }">confirm.delete.plural</p>
  <p *ngIf="processing">{{ 'confirm.delete.progress' | translate }}</p>
  <p *ngIf="processing" translate [translateParams]="{ totalDeleted: totalDocsDeleted, totalSelected: totalDocsSelected }">confirm.delete.totals</p>
</mm-modal-layout>
