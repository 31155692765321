<mm-modal-layout
  id="delete-confirm"
  [processing]="processing"
  [error]="error"
  [titleKey]="'bulkdelete.confirm.title'"
  [submitKey]="'bulkdelete.confirm.action'"
  (onCancel)="close()"
  (onSubmit)="submit()"
>
  <p>{{ 'confirm.delete' | translate }}</p>
</mm-modal-layout>
