<div class="page scrolling">
  <div class="item-content empty-selection" *ngIf="targetsDisabled">
    <div>{{ 'targets.disabled' | translate }}</div>
  </div>

  <div class="item-content empty-selection" *ngIf="!targetsDisabled && loading">
    <div><div class="loader"></div></div>
  </div>

  <div class="item-content empty-selection" *ngIf="!targetsDisabled && !loading && !targets?.length && !errorStack">
    <div>{{ 'targets.no_targets' | translate }}</div>
  </div>

  <error-log class="targets" *ngIf="!targetsDisabled && !loading && !!errorStack" [errorStack]="errorStack" [errorFor]="'targets'"></error-log>

  <div class="targets" *ngIf="!targetsDisabled && !loading && targets?.length && !errorStack">
    <div class="target" *ngFor="let target of targets"
      [ngClass]="{ 'has-goal': target.goal >= 0, 'goal-met': (target.value?.pass >= target.goal) || (target.value?.percent >= target.goal) }"
      attr.test-target-id="{{ target.id }}">
      <div class="body">
        <mm-analytics-targets-progress
          *ngIf="target.type === 'percent'"
          [target]="target" [value]="target.value"
          [aggregate]="false">
        </mm-analytics-targets-progress>
        <div class="count" *ngIf="target.type !== 'percent'">
          <div class="goal" *ngIf="target.goal >= 0">
            <p>{{ 'analytics.target.monthly_goal' | translate }} {{ target.goal | localizeNumber }}</p>
          </div>
          <div class="number">{{ target.value?.pass | localizeNumber }}</div>
        </div>
      </div>
      <div class="heading">
        <div class="icon" [innerHTML]="target.icon | resourceIcon"></div>
        <div class="title">
          <h2 *ngIf="target.translation_key">{{ target.translation_key | translate }}</h2>
          <h2 *ngIf="!target.translation_key">{{ target.name | translateFrom }}</h2>
          <p *ngIf="target.subtitle_translation_key">{{ target.subtitle_translation_key | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
