<div class="content-pane right-pane">
  <div class="col-sm-8 item-content empty-selection" *ngIf="loadingContent">
    <div>
      <div class="loader"></div>
    </div>
  </div>
  <div class="col-sm-8 item-content empty-selection" *ngIf="!loadingContent && contentError">
    <div *ngIf="errorTranslationKey">{{ errorTranslationKey | translate}}</div>
  </div>
  <div class="col-sm-8 item-content material" [hidden]="loadingContent || contentError">
    <div class="card">
      <mm-enketo formId="contact-form" [editing]="enketoContact?.docId" [status]="enketoStatus" (onSubmit)="save()" (onCancel)="navigationCancel()"></mm-enketo>
    </div>
  </div>
</div>
