<div class="page" *ngIf="!enabled || loading">
  <div class="item-content empty-selection disabled" *ngIf="!loading; else loaderTpl">
    <div>{{'analytics.target.aggregates.disabled' | translate}}</div>
  </div>
</div>

<div class="page target-aggregates" *ngIf="enabled && !loading">
  <div id="target-aggregates-list" class="col-sm-4 inbox-items left-pane">
    <ul>
      <mm-content-row *ngFor="let aggregate of aggregates"
        [id]="aggregate.id"
        [route]="['/', 'analytics', 'target-aggregates', aggregate.id]"
        [selected]="aggregate.selected"
        [icon]="aggregate.icon"
        [heading]="aggregate.heading"
        [aggregate]="aggregate.aggregateValue">
      </mm-content-row>
    </ul>
    <p *ngIf="error && !aggregates" class="alert alert-danger" role="alert">{{'analytics.target.aggregates.error' | translate}}</p>
    <p class="loading-status" *ngIf="!error && !aggregates?.length">{{'analytics.target.aggregates.no.targets' | translate}}</p>
  </div>

  <router-outlet></router-outlet>
</div>

<ng-template #loaderTpl>
  <div class="item-content empty-selection">
    <div>
      <div class="loader"></div>
    </div>
  </div>
</ng-template>
