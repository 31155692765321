<div class="row header">
  <div class="inner">
    <a routerLink="home">
      <div class="logo">
        <div class="logo-full" [innerHtml]="'logo' | headerLogo"></div>
      </div>
    </a>

    <div class="extras">

      <span class="dropdown options" dropdown>
        <a href dropdownToggle (click)="false" id="header-dropdown-link" aria-controls="header-dropdown">
          <div class="mm-icon mm-icon-inverse">
            <span class="fa fa-fw fa-bars"></span>
          </div>
        </a>
        <ul id="header-dropdown" *dropdownMenu class="dropdown-menu mm-dropdown-menu" role="menu" aria-labelledby="header-dropdown-link">
          <li role="presentation" mmAuth="can_view_messages,!can_view_messages_tab">
            <a role="menuitem" tabindex="-1" routerLink="messages">
              <i class="fa fa-fw fa-envelope"></i>
              <span>{{'Messages' | translate}}</span>
            </a>
          </li>
          <li role="presentation" mmAuth="can_view_tasks,!can_view_tasks_tab">
            <a role="menuitem" tabindex="-1" routerLink="tasks">
              <i class="fa fa-fw fa-flag"></i>
              <span>{{'Tasks' | translate}}</span>
            </a>
          </li>
          <li role="presentation" mmAuth="can_view_reports,!can_view_reports_tab">
            <a role="menuitem" tabindex="-1" routerLink="reports">
              <i class="fa fa-fw fa-list-alt"></i>
              <span>{{'Reports' | translate}}</span>
            </a>
          </li>
          <li role="presentation" mmAuth="can_view_contacts,!can_view_contacts_tab">
            <a role="menuitem" tabindex="-1" routerLink="contacts">
              <i class="fa fa-fw fa-user"></i>
              <span>{{'Contacts' | translate}}</span>
            </a>
          </li>

          <li role="presentation" mmAuth="can_view_analytics,!can_view_analytics_tab">
            <a role="menuitem" tabindex="-1" routerLink="analytics" routerLink-opts="{reload: true}">
              <i class="fa fa-bar-chart-o"></i>
              <span>{{'Analytics' | translate}}</span>
            </a>
          </li>
          <li role="presentation" class="desktop-only" mmAuth [mmAuthAny]="[ 'can_configure', 'can_view_outgoing_messages', 'can_export_all' ]" [mmAuthOnline]="true">
            <a href="{{adminUrl}}" role="menuitem" tabindex="-1" rel="noopener noreferrer">
              <i class="fa fa-fw fa-cog"></i>
              <span>{{'admin.app.name' | translate}}</span>
            </a>
          </li>

          <li role="presentation disabled" *ngIf="!replicationStatus.disabled" [ngClass]="{ 'disabled': replicationStatus.current?.disableSyncButton }">
            <a role="menuitem" tabindex="-1" (click)="!replicationStatus.current?.disableSyncButton && replicate()">
              <i class="fa fa-fw fa-refresh"></i>
              <span>{{'sync.now' | translate}}</span>
            </a>
          </li>

          <li role="separator" class="divider" *ngIf="!replicationStatus.disabled"></li>

          <li role="presentation disabled" *ngIf="!replicationStatus.disabled" class="sync-status">
            <a class="no-click" [ngClass]="replicationStatus.current?.className" *ngIf="replicationStatus.current">
              <i class="fa fa-fw" [ngClass]="replicationStatus.current?.icon"></i>
              <span>{{replicationStatus.current.key | translate}}</span>
            </a>
            <a class="no-click last-sync" *ngIf="replicationStatus.lastSuccessTo">
              <span>{{'sync.last_success' | translate}}</span>
              <span [innerHTML]="replicationStatus.lastSuccessTo | relativeDate"></span>
            </a>
          </li>

          <li role="separator" class="divider" *ngIf="!replicationStatus.disabled"></li>

          <li role="presentation">
            <a routerLink="about" role="menuitem" tabindex="-1">
              <i class="fa fa-fw fa-question"></i>
              <span>{{'about' | translate}}</span>
            </a>
          </li>

          <li role="presentation" mmAuth="can_edit_profile">
            <a role="menuitem" tabindex="-1" routerLink="user" test-id="user-settings-menu-option">
              <i class="fa fa-fw fa-user"></i>
              <span>{{'edit.user.settings' | translate}}</span>
            </a>
          </li>
          <li role="presentation" *ngFor="let website of customWebsite">
            <a role="menuitem" tabindex="-1" href="{{ website.url }}" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-fw fa-globe"></i>
              <span>{{website.name | translate}}</span>
            </a>
          </li>

          <li role="presentation" *ngIf="showPrivacyPolicy">
            <a role="menuitem" tabindex="-1" routerLink="privacy-policy">
              <i class="fa fa-fw fa-lock"></i>
              <span>{{'privacy.policy' | translate}}</span>
            </a>
          </li>
          <li role="presentation">
            <a role="menuitem" tabindex="-1" (click)="openFeedback()">
              <i class="fa fa-fw fa-bug"></i>
              <span>{{'Report Bug' | translate}}</span>
            </a>
          </li>
          <li role="presentation" *ngIf="canLogOut">
            <a role="menuitem" tabindex="-1" rel="external" (click)="logout()">
              <i class="fa fa-fw fa-power-off"></i>
              <span>{{'Log Out' | translate}}</span>
            </a>
          </li>
        </ul>
      </span>
    </div>

    <div class="tabs small-font">
      <a *ngFor="let tab of permittedTabs" routerLink="{{tab.route}}" id="{{tab.name}}-tab" class="{{tab.name}}-tab" [class.selected]="currentTab === tab.name">
        <div class="mm-icon" [class.mm-icon-inverse]="currentTab !== tab.name">
          <span *ngIf="!tab.resourceIcon" class="fa {{tab.icon}}"><span class="fa {{tab.defaultIcon}}"></span></span>
          <span *ngIf="tab.resourceIcon" [innerHTML]="tab.resourceIcon | resourceIcon:tab.defaultIcon"></span>
          <span class="mm-badge-overlay mm-badge-overlay-top" *ngIf="unreadCount[tab.typeName] > 0">
            <span class="mm-badge mm-badge-red mm-badge-border" *ngIf="unreadCount[tab.typeName] < 100">{{unreadCount[tab.typeName] | localizeNumber}}</span>
            <span class="mm-badge mm-badge-red mm-badge-border" *ngIf="unreadCount[tab.typeName] >= 100">{{'100 +' | localizeNumber}}</span>
          </span>
        </div>
        <div class="button-label">{{tab.translation | translate}}</div>
      </a>
    </div>

    <mobile-detection></mobile-detection>
  </div>
</div>
