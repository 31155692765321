<div class="app-root {{currentTab}}"
     [class.bootstrapped]="initialisationComplete"
     [class.select-mode]="selectMode"
     [class.sidebar-filter-active]="isSidebarFilterOpen">
  <div class="bootstrap-layer">
    <div>
      <div class="loader"></div>
      <div class="status"></div>
      <div class="error"></div>
    </div>
  </div>

  <mm-privacy-policy [overlay]="true" *ngIf="showPrivacyPolicy && !privacyPolicyAccepted"></mm-privacy-policy>

  <mm-actionbar *ngIf="useOldActionBar" [reportForms]="reportForms"></mm-actionbar>

  <div class="container-fluid" *ngIf="initialisationComplete">
    <mm-header
      [adminUrl]="adminUrl"
      [canLogOut]="canLogOut"
    ></mm-header>
    
    <div class="row content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <snackbar></snackbar>
</div>


