<div class="dropdown mm-dropdown" [class.disabled]="disabled" dropdown [insideClick]="true">
  <a [id]="fieldId" class="mm-button" href dropdownToggle (click)="false">
    <span class="mm-button-icon">
       <span class="fa fa-calendar"></span>
    </span>
    <span class="mm-button-dropdown">
      <span class="fa fa-chevron-down"></span>
    </span>
    <span class="mm-button-text" *ngIf="inputLabel">{{ inputLabel }}</span>
    <span class="mm-button-text" *ngIf="!inputLabel">{{ 'Any date' | translate }}</span>
  </a>
</div>
