import { Component } from '@angular/core';

@Component({
  selector: '<report-verify-valid-icon>',
  templateUrl: './report-verify-valid-icon.html'
})
export class ReportVerifyValidIconComponent {
  constructor() {}
}

@Component({
  selector: '<report-verify-invalid-icon>',
  templateUrl: './report-verify-invalid-icon.html'
})
export class ReportVerifyInvalidIconComponent {
  constructor() {}
}

