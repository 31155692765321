<mm-modal-layout
  id="update-password"
  [processing]="processing"
  [error]="errors?.submit"
  [titleKey]="'password.update'"
  [submitKey]="'Update'"
  [isFlatButton]="true"
  (onCancel)="close()"
  (onSubmit)="updatePassword()"
>
  <form action="" method="POST">
    <div class="help-block">
      <label>{{'online.action.title' | translate}}</label>
    </div>
    <div class="form-group required" [ngClass]="{'has-error': errors?.currentPassword}">
      <label for="currentPassword">{{'user.password.current' | translate}}</label>
      <input id="currentPassword" type="password" class="form-control"
             [(ngModel)]="editUserModel.currentPassword"
             name="currentPassword"
             autocomplete="off"/>
      <span class="help-block">{{errors?.currentPassword}}</span>
    </div>

    <div class="form-group required" [ngClass]="{'has-error': errors?.password}">
      <label for="password">{{'Password' | translate}}</label>
      <input id="password" type="password" class="form-control"
             [(ngModel)]="editUserModel.password"
             name="password"
             autocomplete="off"/>
      <span class="help-block">{{errors?.password}}</span>
    </div>

    <div class="form-group required">
      <label for="password-confirm">{{'Confirm Password' | translate}}</label>
      <input id="password-confirm" type="password" class="form-control"
             [(ngModel)]="editUserModel.passwordConfirm"
             name="passwordConfirm"
             autocomplete="off"/>
    </div>

  </form>
</mm-modal-layout>
