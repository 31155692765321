<div class="content-pane right-pane">

  <div class="item-content empty-selection" *ngIf="loadingContent">
    <div><div class="loader"></div></div>
  </div>

  <div class="item-content empty-selection" *ngIf="(!selectedConversation?.messages || !selectedConversation?.messages[0]) && !loadingContent">
    <div>{{'No message selected' | translate}}</div>
  </div>

  <div class="message-content-wrapper item-content" *ngIf="selectedConversation?.messages && selectedConversation?.messages[0] && !loadingContent">
    <div class="body">

      <div id="message-header" class="name-header">
        <mm-sender [message]="selectedConversation.contact"></mm-sender>
      </div>

      <div id="message-content">
        <div class="loader" *ngIf="loadingMoreContent"></div>
        <p class="loading-status" *ngIf="allLoaded">{{'No more messages' | translate}}</p>
        <div>
          <ul>
            <li *ngFor="let message of selectedConversation.messages; trackBy: listTrackBy; let lastMessage = last"
              [ngClass]="{'outgoing': message.doc?.kujua_message, 'incoming': !message.doc?.kujua_message}"
              [attr.data-id]="message.doc?._id">

              <div class="marker" *ngIf="firstUnread && firstUnread.id === message.doc?._id">{{'Unread below' | translate}}</div>
              <span class="message-body" [class.last-message]="lastMessage">
                <div class="data">
                  <p [attr.test-id]="'sms-content'">
                    {{message.doc?.sms_message?.message}}
                    <span *ngIf="message.doc?.kujua_message && message.doc?.tasks && message.doc?.tasks[0]?.messages">
                      {{message.doc?.tasks[0]?.messages[0]?.message}}
                    </span>
                  </p>

                  <!-- outgoing message -->
                  <span class="task-state" *ngIf="message.doc?.kujua_message && message.doc?.tasks" [innerHTML]="(message.doc?.tasks[0] | state) | async"></span>

                  <!-- incoming message -->
                  <span class="task-state" *ngIf="!message.doc?.kujua_message" [innerHTML]="(message.doc | state) | async"></span>

                  <!-- incoming message autoreply -->
                  <span class="task-state" *ngIf="!message.doc?.kujua_message && message.doc?.tasks" [innerHTML]="(message.doc?.tasks[0] | autoreply) | async"></span>
                </div>

                <div class="actions">
                  <a class="btn btn-link fa fa-trash-o" (click)="deleteDoc(message.doc)" title="{{ 'Delete' | translate }}" mmAuth="can_edit,can_delete_messages"></a>
                </div>
              </span>

            </li>
          </ul>
        </div>
      </div>

      <div
        mmAuth="can_edit"
        id="message-footer"
        class="reply message-form"
        [ngClass]="{sending: textAreaFocused || send.message?.length}">

        <ng-container *ngIf="selectedConversation.messages[0] && (selectedConversation.contact?.name || selectedConversation.contact?.doc?.name) else unknownContactMessage">
          <div class="control-group">
            <div class="controls">
              <textarea name="message"
                [(ngModel)]="send.message"
                placeholder="{{ 'Reply to name' | translate:{ contact: (selectedConversation.contact?.doc?.name || selectedConversation.contact?.name || '')} }}">
              </textarea>
            </div>
          </div>

          <div class="message-actions" *ngIf="textAreaFocused || send.message?.length">
            <button class="btn btn-link btn-add-recipients"
              (click)="addRecipients()"
              (mouseover)="isAddRecipientBtnActive = true"
              (mouseout)="isAddRecipientBtnActive = false">
              {{'Add recipient' | translate}}
            </button>
            <!-- Count is done by CountMessageService -->
            <span class="count"></span>
            <button class="btn submit btn-primary"
              (click)="sendMessage()"
              [disabled]="!send.message?.length"
              [ngClass]="{disabled: !send.message?.length}">
              {{'Send' | translate}}
            </button>
          </div>
        </ng-container>

      </div>

    </div>
  </div>
</div>

<ng-template #unknownContactMessage>
  <p id="unknown-contact-error-message" class="alert alert-danger" role="alert">
    {{'messages.errors.unknown.contact' | translate}}
  </p>
</ng-template>
