<div class="alert alert-danger" role="alert">
  <div class="error-details">
    <span>{{'Error fetching ' + errorFor | translate}}</span>
    <a *ngIf="!!reload" (click)="reload()">{{'Reload' | translate}}</a>
    <dl>
      <dt>{{'User Name' | translate}}</dt>
      <dd>{{userName}}</dd>
      <dt>{{'url' | translate}}</dt>
      <dd>{{url}}</dd>
      <dt>{{'date.time.title' | translate}}</dt>
      <dd>{{currentDate | simpleDateTime}}</dd>
      <dt *ngIf="!replicationStatus?.disabled && replicationStatus?.lastSuccessTo">
        {{'sync.last_success' | translate}}
      </dt>
      <dd *ngIf="!replicationStatus?.disabled && replicationStatus?.lastSuccessTo">
        {{replicationStatus?.lastSuccessTo | simpleDateTime}}
      </dd>
    </dl>
  </div>
  <pre><code>{{errorStack}}</code></pre>
</div>
