<div class="filters">
  <div class="inner">
    <mm-analytics-filters [analyticsModules]="analyticsModules"></mm-analytics-filters>
    <mm-navigation></mm-navigation>
  </div>
</div>

<div class="inner">
  <router-outlet></router-outlet>
</div>

