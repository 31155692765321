<div class="page reporting">
  <ul class="mm-navigation-menu" *ngIf="analyticsModules.length">
    <li *ngFor="let module of analyticsModules">
      <a [routerLink]="module.route">
        <span>{{ module.label | translate }}</span>
      </a>
    </li>
  </ul>
  <div class="loader" *ngIf="loading"></div>
  <div class="item-content empty-selection" *ngIf="!loading && !analyticsModules.length">
    <div>{{'analytics.unconfigured' | translate}}</div>
  </div>
</div>
