<div class="sender" [ngClass]="{ 'small-font': sentBy }" *ngIf="message">
  <div>
    <span *ngIf="sentBy && getName()"
          class="name"
          translate
          [translateParams]="{ senderName: getName() || ( 'messages.unknown.sender' | translate ) }">
      messages.sent.by
    </span>

    <a class="name" *ngIf="!sentBy && getId() && getName(); else noLinkLabel" [routerLink]="[ '/contacts', getId() ]">{{ getName() }}</a>
    <ng-template #noLinkLabel>
      <span class="name" *ngIf="!sentBy || !getName()">{{ getName() || ( 'messages.unknown.sender' | translate ) }}</span>
    </ng-template>

    <span class="phone" *ngIf="!(message.name && message.name === message.facility?.contact?.phone)">
      {{ message.doc?.phone || message.contact?.phone || message.phone }}
    </span>
  </div>

  <div class="position small-font"
       *ngIf="!hideLineage && (message.lineage || (message.contact && message.contact.parent) || message.place || (!message.form && message.name))"
       [innerHTML]="(message.lineage || (message.contact && message.contact.parent) || message.place) | lineage">
  </div>
</div>
