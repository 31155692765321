<mm-modal-layout
  id="training-cards-modal"
  [titleKey]="showConfirmExit ? 'training_cards.confirm.title' : modalTitleKey | translate"
  (onCancel)="confirmExit(true)"
  [hideFooter]="hideModalFooter"
  [hasEnketoForm]="true"
>

  <div class="item-content empty-selection" *ngIf="loadingContent">
    <div><div class="loader"></div></div>
  </div>

  <div class="item-content empty-selection" *ngIf="!loadingContent && contentError">
    <div>{{ errorTranslationKey | translate }}</div>
  </div>

  <div class="item-content" *ngIf="showConfirmExit && !loadingContent && !contentError">
    <div>{{ 'training_cards.confirm.exit' | translate }}</div>
    <div class="modal-footer">
      <button test-id="cancel" mat-button color="primary" (click)="confirmExit(false)">{{ 'training_cards.confirm.button.no' | translate }}</button>
      <button test-id="quit-training" mat-button color="primary" (click)="quitTraining()">{{ 'training_cards.confirm.button.yes' | translate }}</button>
    </div>
  </div>

  <div class="item-content" [hidden]="showConfirmExit || loadingContent || contentError" [class.form-no-title]="formNoTitle">
    <div class="body">
      <mm-enketo
        [formId]="formWrapperId"
        [status]="enketoStatus"
        (onSubmit)="saveForm()"
        (onCancel)="confirmExit(true)">
      </mm-enketo>
    </div>
  </div>

</mm-modal-layout>
