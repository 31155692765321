<mm-modal-layout
  id="edit-message-group"
  [processing]="processing"
  [error]="error"
  [titleKey]="'edit_message_group.modal.title'"
  [submitKey]="'save'"
  [submittingKey]="'saving'"
  [isFlatButton]="true"
  (onCancel)="close()"
  (onSubmit)="submit()"
>
  <form action="" method="POST">

    <fieldset *ngFor="let task of group.rows; index as index" [disabled]="task.state !== 'scheduled' && task.state !== 'muted'"><!-- //NOSONAR -->
      <ng-container *ngIf="!task.deleted">
        <div class="form-group">
          <label>
            <span>{{'tasks.0.messages.0.message' | translate}}</span>
            <a class="btn btn-link fa fa-trash-o" (click)="deleteTask(index)" *ngIf="task.state === 'scheduled' || task.state === 'muted'"></a>
          </label>
          <input type="text" class="form-control" *ngFor="let message of task.messages" [(ngModel)]="message.message" [ngModelOptions]="{standalone: true}" [disabled]="task.hasOwnProperty('translation_key')"/>
        </div>

        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
            <input type="text" class="form-control datepicker" [value]="task.due" [attr.data-index]="index" />
          </div>
        </div>
      </ng-container>
    </fieldset>

    <a class="btn" (click)="addTask()">
      <i class="fa fa-plus"></i>
      <span>{{'Add Message' | translate}}</span>
    </a>

  </form>
</mm-modal-layout>
