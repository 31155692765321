<div class="filters">
  <div class="inner">
    <mm-navigation></mm-navigation>
  </div>
</div>

<div class="inner">
  <div class="inbox page">

    <div id="tasks-list" class="col-sm-4 inbox-items left-pane">
      <ul *ngIf="!errorStack && hasTasks">
        <li *ngFor="let task of tasksList; trackBy: listTrackBy"
            [attr.data-record-id]="task._id"
            [class.selected] = "task.selected"
            [class.overdue]="task.overdue"
            class="content-row"
        >
          <a [routerLink]="['/', 'tasks', task._id]" [state]="{ tab: 'tasks', id: task._id }">
            <div *ngIf="task.icon" class="icon" [innerHTML]="task.icon | resourceIcon"></div>
            <div class="content">
              <div class="heading">
                <h4>
                  <span>{{task.contact?.name || ''}}</span>
                </h4>
                <div class="date">
                  <span [innerHTML]="task.date | taskDueDate"></span>
                </div>
              </div>

              <div *ngIf="task.title" class="summary">
                <p>{{task.title}}</p>
              </div>

              <div *ngIf="task.priorityLabel" class="warning">{{task.priorityLabel}}</div>
              <div *ngIf="task.lineage?.length" class="detail" [innerHTML]="task.lineage | lineage"></div>
            </div>
          </a>
        </li>
      </ul>

      <error-log *ngIf="!!errorStack" [errorStack]="errorStack" [errorFor]="'tasks'" [reload]="refreshTaskList"></error-log>

      <p class="loading-status" *ngIf="!errorStack && !loading && !hasTasks && !tasksDisabled">{{'No tasks found' | translate}}</p>
      <p class="loading-status" *ngIf="!errorStack && !loading && hasTasks && !tasksDisabled">{{'task.list.complete' | translate}}</p>
      <p class="loading-status" *ngIf="tasksDisabled">{{'tasks.disabled' | translate}}</p>
      <div class="loader" *ngIf="loading && !tasksDisabled && !errorStack"></div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
