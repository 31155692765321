<ng-container>
  <div class="empty-selection" *ngIf="loadingContent">
    <div>
      <div class="loader"></div>
    </div>
  </div>

  <div class="empty-selection" *ngIf="!loadingContent && contentError">
    <div>{{ errorTranslationKey | translate}}</div>
  </div>

  <div class="report-wrap" [hidden]="loadingContent || contentError">
    <div class="body">
      <mm-enketo formId="report-form" [status]="enketoStatus" (onSubmit)="save()" (onCancel)="navigationCancel()"></mm-enketo>
    </div>
  </div>
</ng-container>
